
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Information1 from "@/components/dropdown/Information1.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";
import ElInfo from "@/components/ElInfo.vue";
import Multiselect from "@vueform/multiselect";
import VuePdfEmbed from "vue-pdf-embed/dist/vue3-pdf-embed";
import ProfilingQuestinaire from "../profilingQuestionnaire/ProfilingQuestionnaire.vue";

export default defineComponent({
  name: "financialResolution",
  components: {
    Field,
    ProfilingQuestinaire,
    VuePdfEmbed,
    ElCurrencyInputNoSymbol,
  },
  props: [""],
  async mounted() {
    this.init();
  },
  data() {
    const schema = Yup.object({});
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const showCcrisProcess = false;
    const cstmr_id = route.params.id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {
        id: 0,
        user_id: 0,
        marital_status: "",
        alternate_contact_no: "",
        no_of_dependant: "",
        state: "",
        city: "",
        residential_status: "",
        employment_status: "",
        previous_gross_income: "",
        current_gross_income: "",
        other_loan_commitment: "",
        current_net_income: "",
        spouse_employment_status: "",
        spouse_income: "",
        urs_package: "",
        dmp_reference_number: "",
        approval_date: "",
        created_at: "",
        updated_at: "",
        pfp_profiling: "",
        dmp_profiling: "",
      },
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };
    const customerDataReady = false;
    const dmpProposalReady = false;

    const customerMatrix = {
      CustomerMatrixAnswer1: "1911",
      CustomerMatrixAnswer2: "1911",
      CustomerMatrixAnswer3: "1911",
      CustomerMatrixAnswer4: "1911",
      CustomerMatrixAnswer5: "1911",
      CustomerMatrixAnswer6: "1911",
      CustomerMatrixAnswer7: "1911",
      CustomerMatrixAnswer8: "1911",
      CustomerMatrixAnswer9: "1911",
      CustomerMatrixAnswer10: "1911",
      CustomerMatrixAnswer11: "1911",
      CustomerMatrixAnswer12: "1911",
    };

    const customerProfiling = {
      CustomerProfilingAnswer1: "855",
      CustomerProfilingAnswer2: "856",
      CustomerProfilingAnswer3: "859",
      CustomerProfilingAnswer4: "682",
      CustomerProfilingAnswer5: "695",
      CustomerProfilingAnswer6: "700",
      CustomerProfilingAnswer7: "911",
      CustomerProfilingAnswer8: "942",
      CustomerProfilingAnswer9: "933",
      CustomerProfilingAnswer10: "949",
      CustomerProfilingAnswer11: "924",
      CustomerProfilingAnswer12: "928",
      CustomerProfilingAnswer13: "955",
      CustomerProfilingAnswer14: "917",
      CustomerProfilingAnswer15: "959",
      CustomerProfilingAnswer16: "938",
      CustomerProfilingAnswer17: "968",
      CustomerProfilingAnswer18: "",
      RecurringNonRecurringReasonID: "",
    };

    const crdtr_infrmtn_dtls = {
      scrd_loan: [],
      unscrd_loan: [],
    };

    const rstrctrdInDmp = [
      { value: "Y", label: "Yes" },
      { value: "N", label: "No" },
    ];

    const profQuestionAns = [
      { value: "1910", label: "Yes" },
      { value: "1911", label: "No" },
    ];

    const creditors = [];
    const oriCreditors = [];
    const loanTypes = [
      {
        value: "Secured",
        label: "Secured",
      },
      {
        value: "Unsecured",
        label: "Unsecured",
      },
    ];

    const installmentMethods = [
      {
        value: 1865,
        label: "AKPK",
      },
      {
        value: 448,
        label: "Self",
      },
      {
        value: 450,
        label: "Salary Deductions",
      },
      {
        value: 449,
        label: "Angkasa",
      },
    ];

    const isSingle = [
      {
        value: "Single",
        label: "Single",
      },
      {
        value: "StepUp",
        label: "StepUp",
      },
    ];

    const ccrisUrl = "";
    const ccrisPath = "";

    const creditor = {
      loan_type: "",
      nm_of_crdtr: "",
      fclty_typ: "",
      fclty_nmbr: "",
      rstrctrd_in_dmp: "Y",
      lmt: 0,
      ori_outstndng_blnc: 0,
      outstndng_blnc: 0,
      ori_principle_amount: 0,
      disc_principle_amount: 0,
      principle_amount: 0,
      ori_iis: 0,
      disc_iis: 0,
      iis: 0,
      installment_amount: 0,
      ori_penalty_interest: 0,
      disc_penalty_interest: 100,
      penalty_interest: 0,
      ori_other_chargers: 0,
      disc_other_chargers: 0,
      other_chargers: 0,
      tenure: 120,
      interest: 0,
      legal_status_date: "",
      installment_method: 1865,
    };

    const creditorCodes = {
      "AEON Credit Service (M) Bhd": "3905",
      "AGRO Bank": "3306",
      "Affin Bank Berhad": "0232",
      "Affin Islamic Bank Berhad": "0347",
      "Al Rajhi Banking & Investment Corporation (Malaysia) Berhad": "0350",
      "Alliance Bank Malaysia Berhad": "0212",
      "Alliance Islamic Bank Berhad": "0353",
      "AmBank (M) Berhad": "0208",
      "AmIslamic Bank Berhad": "0349",
      "American International Assurance Company Ltd.": "3404",
      "BNP Paribas Malaysia Berhad": "0263",
      "Bangkok Bank Berhad": "0204",
      "Bank Islam Malaysia Berhad": "0340",
      "Bank Kerjasama Rakyat Malaysia Berhad": "3311",
      "Bank Muamalat Malaysia Berhad": "0341",
      "Bank Simpanan Nasional": "3310",
      "Bank of America Malaysia Berhad": "0207",
      "Bank of China (Malaysia) Berhad": "0242",
      "Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad": "0210",
      "CIMB Bank Berhad": "0235",
      "CIMB Islamic Bank Berhad": "0344",
      "Citibank Berhad": "0217",
      "Deutsche Bank (Malaysia) Berhad": "0219",
      "Diners Card (Malaysia) Sdn. Bhd.": "3907",
      "HSBC Amanah Malaysia Berhad": "0356",
      "HSBC Bank Malaysia Berhad": "0222",
      "Hong Leong Bank Berhad": "0224",
      "Hong Leong Islamic Bank Berhad": "0345",
      "ING Insurance Berhad": "3401",
      "India International Bank (Malaysia) Berhad": "0260",
      "Industrial and Commercial Bank of China (Malaysia) Berhad": "0259",
      "J.P. Morgan Chase Bank Berhad": "0215",
      "Kuwait Finance House (Malaysia) Berhad": "0346",
      "MBF Cards (Malaysia) Sdn. Bhd.": "3903",
      "MBSB Bank Berhad": "0352",
      "Malayan Banking Berhad": "0227",
      "Maybank Islamic Berhad": "0354",
      "Mizuho Bank (Malaysia) Berhad": "0261",
      "National Bank of Abu Dhabi Malaysia Berhad": "0264",
      "OCBC Al-Amin Bank Berhad": "0357",
      "OCBC Bank (Malaysia) Berhad": "0229",
      "Perbadanan Tabung Pendidikan Tinggi Nasional": "7401",
      "Public Bank Berhad": "0233",
      "Public Islamic Bank Berhad": "0351",
      "RHB Bank Berhad": "0218",
      "RHB Islamic Bank Berhad": "0343",
      "Standard Chartered Bank Malaysia Berhad": "0214",
      "Standard Chartered Saadiq Berhad": "0358",
      "Sumitomo Mitsui Banking Corporation Malaysia Berhad": "0262",
      "The Bank of Nova Scotia Berhad": "0209",
      "United Overseas Bank (Malaysia) Berhad": "0226",
    };

    const fcltyTyp = [
      {
        id: "19965",
        ParameterValue: "Aiqon -  Hire Purchase (Loss After Sale) (PCPASCAR)",
      },
      { id: "19963", ParameterValue: "Aiqon - Credit Card (CRDTCARD)" },
      {
        id: "19966",
        ParameterValue: "Aiqon - Housing Loan (Loss After Sale) (HSLNFNCE)",
      },
      { id: "19967", ParameterValue: "Aiqon - Personal Financing (PELNFNCE)" },
      { id: "19964", ParameterValue: "Aiqon - Personal Loan (PELNFNCE)" },
      { id: "444", ParameterValue: "ASB (Loss After Sales)" },
      { id: "3362", ParameterValue: "ASB (Loss After Sales) CP" },
      { id: "436", ParameterValue: "ASB Loan/Financing" },
      { id: "431", ParameterValue: "BBA Financing" },
      { id: "3357", ParameterValue: "BBA Financing CP" },
      { id: "432", ParameterValue: "BBA House Financing" },
      { id: "5573", ParameterValue: "BIMB -Vehicle Financing (PCPASCAR)" },
      { id: "5646", ParameterValue: "BIMB - Auto Finance (PCPASCAR)" },
      { id: "9827", ParameterValue: "Business - Credit Card (CRDTCARD)" },
      { id: "9826", ParameterValue: "Business - Credit Card (CP) (CRDTCARD)" },
      { id: "9801", ParameterValue: "Business - Hire Purchase (PCPASCAR)" },
      {
        id: "9807",
        ParameterValue: "Business - Hire Purchase (Loss After Sale) (PCPASCAR)",
      },
      { id: "9800", ParameterValue: "Business - House Financing (HSLNFNCE)" },
      { id: "9799", ParameterValue: "Business - Housing Loan (HSLNFNCE)" },
      {
        id: "9811",
        ParameterValue:
          "Business - Housing Loan (Abandoned Project) (HSLNFNCE)",
      },
      {
        id: "9808",
        ParameterValue: "Business - Housing Loan (Loss After Sale) (HSLNFNCE)",
      },
      { id: "9824", ParameterValue: "Business - Leasing" },
      { id: "9825", ParameterValue: "Business - Leasing (Loss After Sale)" },
      { id: "9809", ParameterValue: "Business - Line Of Credit" },
      { id: "9821", ParameterValue: "Business - Machinery Financing" },
      {
        id: "9823",
        ParameterValue: "Business - Machinery Financing (Loss After Sale)",
      },
      { id: "9820", ParameterValue: "Business - Machinery Loan" },
      {
        id: "9822",
        ParameterValue: "Business - Machinery Loan (Loss After Sale)",
      },
      { id: "9812", ParameterValue: "Business - Margin Financing (SHMARGIN)" },
      {
        id: "9814",
        ParameterValue:
          "Business - Margin Financing (Loss After Sale) (SHMARGIN)",
      },
      { id: "9813", ParameterValue: "Business - Margin Loan (SHMARGIN)" },
      {
        id: "9815",
        ParameterValue: "Business - Margin Loan (Loss After Sale) (SHMARGIN)",
      },
      { id: "9805", ParameterValue: "Business - Micro Credit" },
      { id: "9806", ParameterValue: "Business - Micro Financing" },
      {
        id: "9810",
        ParameterValue:
          "Business - Mortgage Financing (Loss After Sale) (HSLNFNCE)",
      },
      { id: "9803", ParameterValue: "Business - Overdraft (Clean) (OVRDRAFT)" },
      {
        id: "9804",
        ParameterValue: "Business - Overdraft (Secured) (OVRDRAFT)",
      },
      {
        id: "9816",
        ParameterValue: "Business - Shoplot / Shophouse Financing (HSLNFNCE)",
      },
      {
        id: "9818",
        ParameterValue:
          "Business - Shoplot / Shophouse Financing (Loss After Sale) (HSLNFNCE)",
      },
      {
        id: "9817",
        ParameterValue: "Business - Shoplot / Shophouse Loan (HSLNFNCE)",
      },
      {
        id: "9819",
        ParameterValue:
          "Business - Shoplot / Shophouse Loan (Loss After Sale) (HSLNFNCE)",
      },
      {
        id: "9797",
        ParameterValue: "Business - Term Financing (Clean) (OTLNENCE)",
      },
      {
        id: "9798",
        ParameterValue: "Business - Term Financing (Secured) (OTLNENCE)",
      },
      { id: "9795", ParameterValue: "Business - Term Loan (Clean) (OTLNENCE)" },
      {
        id: "9796",
        ParameterValue: "Business - Term Loan (Secured) (OTLNENCE)",
      },
      { id: "9802", ParameterValue: "Business - Vehicle Financing (PCPASCAR)" },
      { id: "118", ParameterValue: "Charge Cards (CHRGCARD)" },
      { id: "3349", ParameterValue: "Charge Cards CP (CHRGCARD)" },
      { id: "19987", ParameterValue: "Commercial Property Financing" },
      { id: "119", ParameterValue: "Credit Cards (CRDTCARD)" },
      { id: "3350", ParameterValue: "Credit Cards CP (CRDTCARD)" },
      { id: "122", ParameterValue: "Fixed Loan (Clean)" },
      { id: "3353", ParameterValue: "Fixed Loan (Clean) CP" },
      { id: "425", ParameterValue: "Fixed Loan (Secured)" },
      { id: "3310", ParameterValue: "Home Financing (HSLNFNCE)" },
      { id: "430", ParameterValue: "Housing Loan (HSLNFNCE)" },
      {
        id: "3340",
        ParameterValue: "Housing Loan (Abandoned Project) (HSLNFNCE)",
      },
      {
        id: "445",
        ParameterValue: "Housing Loan (Loss After Sales) (HSLNFNCE)",
      },
      {
        id: "3363",
        ParameterValue: "Housing Loan (Loss After Sales) CP (HSLNFNCE)",
      },
      { id: "3333", ParameterValue: "HP AEON" },
      { id: "3366", ParameterValue: "HP AEON CP" },
      { id: "434", ParameterValue: "HP Car Loan (PCPASCAR)" },
      {
        id: "443",
        ParameterValue: "HP Car Loan (Loss After Sales) (PCPASCAR)",
      },
      {
        id: "3361",
        ParameterValue: "HP Car Loan (Loss After Sales) CP (PCPASCAR)",
      },
      { id: "3337", ParameterValue: "HP Guarantor (PCPASCAR)" },
      { id: "3216", ParameterValue: "Islamic Car Financing (PCPASCAR)" },
      { id: "426", ParameterValue: "Islamic Fixed Loan" },
      { id: "3354", ParameterValue: "Islamic Fixed Loan CP" },
      { id: "433", ParameterValue: "Islamic Housing Loan (HSLNFNCE)" },
      { id: "3312", ParameterValue: "Line Of Credit" },
      { id: "3365", ParameterValue: "Line Of Credit CP" },
      { id: "446", ParameterValue: "Margin Financing (SHMARGIN)" },
      {
        id: "447",
        ParameterValue: "Margin Financing (Loss After Sales) (SHMARGIN)",
      },
      {
        id: "3364",
        ParameterValue: "Margin Financing (Loss After Sales) CP (SHMARGIN)",
      },
      { id: "441", ParameterValue: "Micro Credit" },
      { id: "3359", ParameterValue: "Micro Credit CP" },
      { id: "442", ParameterValue: "Micro Financing" },
      { id: "3360", ParameterValue: "Micro Financing CP" },
      { id: "437", ParameterValue: "Mortgage Financing (HSLNFNCE)" },
      {
        id: "3336",
        ParameterValue: "Mortgage Financing (Loss After Sales) (HSLNFNCE)",
      },
      {
        id: "3367",
        ParameterValue: "Mortgage Financing (Loss After Sales) CP (HSLNFNCE)",
      },
      { id: "3341", ParameterValue: "Others (OTLNFNCE)" },
      { id: "438", ParameterValue: "Overdraft Clean (OVRDRAFT)" },
      { id: "3358", ParameterValue: "Overdraft Clean CP (OVRDRAFT)" },
      { id: "439", ParameterValue: "Overdraft Secured against FD (OVRDRAFT)" },
      {
        id: "440",
        ParameterValue: "Overdraft Secured against Property (OVRDRAFT)",
      },
      { id: "121", ParameterValue: "Personal Financing (PELNFNCE)" },
      { id: "3352", ParameterValue: "Personal Financing CP (PELNFNCE)" },
      { id: "120", ParameterValue: "Personal Loan (PELNFNCE)" },
      { id: "3351", ParameterValue: "Personal Loan CP (PELNFNCE)" },
      { id: "3311", ParameterValue: "Shares Financing" },
      { id: "5587", ParameterValue: "Study Loan - PTPTN/KPT" },
      { id: "427", ParameterValue: "Term Loan (Clean) (OTLNENCE)" },
      { id: "3355", ParameterValue: "Term Loan (Clean) CP (OTLNENCE)" },
      { id: "428", ParameterValue: "Term Loan (Secured) (OTLNENCE)" },
      { id: "429", ParameterValue: "Term Loan Financing (OTLNENCE)" },
      { id: "3356", ParameterValue: "Term Loan Financing CP (OTLNENCE)" },
      { id: "435", ParameterValue: "Vehicle Financing (PCPASCAR)" },
    ];

    const matrixes = [
      {
        value: "Matrix 1",
        label: "Matrix 1",
      },
      {
        value: "Matrix 2",
        label: "Matrix 2",
      },
      {
        value: "Matrix 3",
        label: "Matrix 3",
      },
      {
        value: "Matrix 4",
        label: "Matrix 4",
      },
      {
        value: "Matrix 5",
        label: "Matrix 5",
      },
      {
        value: "Matrix 2 Collaboration",
        label: "Matrix 2 Collaboration",
      },
    ];

    const cities = [];
    const dmpDstrbtns = [];

    const creditorInformation = {
      grossIncome: 0,
      netIncome: 0,
      stryDdctns: 0,
      fixInstalment: 0,
      ttlHshldExpnss: 0,
      ntDispsblIncm: 0,
    };

    const lastUpdate = "";

    return {
      env: process.env.VUE_APP_ENVIRONMENNT,
      showCcrisProcess,
      ccrisUrl,
      ccrisPath,
      cities,
      crdtr_infrmtn_dtls,
      creditor,
      creditorCodes,
      creditorInformation,
      creditors,
      cstmr_id,
      customer,
      customerDataReady,
      customerMatrix,
      customerProfiling,
      dmpProposalReady,
      dmpDstrbtns,
      fcltyTyp,
      fin_id,
      installmentMethods,
      isLoading: true,
      loanTypes,
      matrixes,
      lastUpdate,
      oriCreditors,
      page: null,
      pageCount: 1,
      profQuestionAns,
      route,
      router,
      rstrctrdInDmp,
      schema: schema,
      showAllPages: false,
      store,
      showSaveBttn: false,
      showFacilities: false,
    };
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  setup() {
    const { t, te, n } = useI18n();
    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const nFrmtThsnd = (data) => {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return {
      nFormat,
      nFrmtThsnd,
    };
  },

  methods: {
    updatePrinAmount(index) {
      let ori_principle_amount = parseFloat(
        this.crdtr_infrmtn_dtls.scrd_loan[index].ori_principle_amount
      );
      let discount =
        (ori_principle_amount *
          this.crdtr_infrmtn_dtls.scrd_loan[index].disc_principle_amount) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.scrd_loan[index].ori_principle_amount -
        discount;
      this.crdtr_infrmtn_dtls.scrd_loan[index].principle_amount = newAmount;

      this.updateOutBalance(index);
    },
    updateUnPrinAmount(index) {
      let discount =
        (this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_principle_amount *
          this.crdtr_infrmtn_dtls.unscrd_loan[index].disc_principle_amount) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_principle_amount -
        discount;
      this.crdtr_infrmtn_dtls.unscrd_loan[index].principle_amount = newAmount;

      this.updateUnOutBalance(index);
    },
    updateIIS(index) {
      let discount =
        (this.crdtr_infrmtn_dtls.scrd_loan[index].ori_iis *
          this.crdtr_infrmtn_dtls.scrd_loan[index].disc_iis) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.scrd_loan[index].ori_iis - discount;

      this.crdtr_infrmtn_dtls.scrd_loan[index].iis = newAmount;

      this.updateOutBalance(index);
    },
    updateUnIIS(index) {
      let discount =
        (this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_iis *
          this.crdtr_infrmtn_dtls.unscrd_loan[index].disc_iis) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_iis - discount;

      this.crdtr_infrmtn_dtls.unscrd_loan[index].iis = newAmount;

      this.updateUnOutBalance(index);
    },
    updatePenInt(index) {
      let discount =
        (this.crdtr_infrmtn_dtls.scrd_loan[index].ori_penalty_interest *
          this.crdtr_infrmtn_dtls.scrd_loan[index].disc_penalty_interest) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.scrd_loan[index].ori_penalty_interest -
        discount;

      this.crdtr_infrmtn_dtls.scrd_loan[index].penalty_interest = newAmount;

      this.updateOutBalance(index);
    },
    updateUnPenInt(index) {
      let discount =
        (this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_penalty_interest *
          this.crdtr_infrmtn_dtls.unscrd_loan[index].disc_penalty_interest) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_penalty_interest -
        discount;

      this.crdtr_infrmtn_dtls.unscrd_loan[index].penalty_interest = newAmount;

      this.updateUnOutBalance(index);
    },
    updateOthCharg(index) {
      let discount =
        (this.crdtr_infrmtn_dtls.scrd_loan[index].ori_other_chargers *
          this.crdtr_infrmtn_dtls.scrd_loan[index].disc_other_chargers) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.scrd_loan[index].ori_other_chargers - discount;

      this.crdtr_infrmtn_dtls.scrd_loan[index].other_chargers = newAmount;

      this.updateOutBalance(index);
    },
    updateUnOthCharg(index) {
      let discount =
        (this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_other_chargers *
          this.crdtr_infrmtn_dtls.unscrd_loan[index].disc_other_chargers) /
        100;
      let newAmount =
        this.crdtr_infrmtn_dtls.unscrd_loan[index].ori_other_chargers -
        discount;

      this.crdtr_infrmtn_dtls.unscrd_loan[index].other_chargers = newAmount;

      this.updateUnOutBalance(index);
    },
    updateOutBalance(index) {
      this.crdtr_infrmtn_dtls.scrd_loan[index].outstndng_blnc =
        this.crdtr_infrmtn_dtls.scrd_loan[index].principle_amount +
        this.crdtr_infrmtn_dtls.scrd_loan[index].iis +
        this.crdtr_infrmtn_dtls.scrd_loan[index].penalty_interest +
        this.crdtr_infrmtn_dtls.scrd_loan[index].other_chargers;
    },
    updateUnOutBalance(index) {
      this.crdtr_infrmtn_dtls.unscrd_loan[index].outstndng_blnc =
        this.crdtr_infrmtn_dtls.unscrd_loan[index].principle_amount +
        this.crdtr_infrmtn_dtls.unscrd_loan[index].iis +
        this.crdtr_infrmtn_dtls.unscrd_loan[index].penalty_interest +
        this.crdtr_infrmtn_dtls.unscrd_loan[index].other_chargers;
    },
    init() {
      setCurrentPageBreadcrumbs("Financial Resolution", []);

      if (this.fin_id) {
        this.store
          .dispatch(Actions.GET_FINANCIAL_RESOLUTION, this.fin_id)
          .then(() => {
            // console.log(
            //   this.store.getters.getFinancialResolutionData.raw
            //     .crdtr_infrmtn_dtls
            // );

            this.customer = this.store.getters.getFinancialResolutionData.raw.customer;
            this.creditorInformation = this.store.getters.getFinancialResolutionData.raw.creditorInformation;
            this.crdtr_infrmtn_dtls = {
              scrd_loan: [],
              unscrd_loan: [],
            };
            this.crdtr_infrmtn_dtls = this.store.getters.getFinancialResolutionData.raw.crdtr_infrmtn_dtls;

            this.customerMatrix = this.store.getters.getFinancialResolutionData.raw.customerMatrix;
            this.customerProfiling = this.store.getters.getFinancialResolutionData.raw.customerProfiling;
            this.dmpDstrbtns = this.store.getters.getFinancialResolutionData.raw.dmpDstrbtns;
            this.customerDataReady = true;
            this.dmpProposalReady = true;

            this.lastUpdate = moment(
              this.store.getters.getFinancialResolutionData.updated_at
            ).format("DD/MM/YYYY");

            this.store
              .dispatch(
                Actions.GET_CCRIS,
                this.store.getters.getFinancialResolutionData.raw.customer
                  .username
              )
              .then(() => {
                const ccris_url = this.store.getters.getCcrisData;

                this.ccrisUrl = ccris_url.data;
                this.ccrisPath = ccris_url.url;
              });

            // console.log(
            //   this.store.getters.getFinancialResolutionData.raw
            //     .crdtr_infrmtn_dtls
            // );

            // console.log(this.crdtr_infrmtn_dtls);
          });
      } else {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;

          this.creditorInformation = {
            grossIncome: this.store.getters.getCustomerData.detail
              .current_gross_income,
            netIncome: this.store.getters.getCustomerData.detail
              .current_net_income,
            stryDdctns: 0,
            fixInstalment: 0,
            ttlHshldExpnss: 0,
            ntDispsblIncm: 0,
          };

          this.store
            .dispatch(
              Actions.GET_CCRIS,
              this.store.getters.getCustomerData.username
            )
            .then(() => {
              const ccris_url = this.store.getters.getCcrisData;

              this.ccrisUrl = ccris_url.data;
              this.ccrisPath = ccris_url.url;
            });
          this.customerDataReady = true;
        });
      }

      setTimeout(() => {
        this.store.dispatch(Actions.GET_CREDITORS).then(() => {
          this.store.dispatch(Actions.GET_FACILITIES).then(() => {
            this.creditors = this.store.getters.getCreditorsData;
            this.oriCreditors = this.store.getters.getCreditorsData;
            const cbsFacilities = this.store.getters.getFacilitiesData;

            for (let crIndex of Object.keys(this.creditors)) {
              for (let coIndex of Object.keys(this.creditorCodes)) {
                if (coIndex == this.creditors[crIndex].ParameterValue) {
                  this.creditors[crIndex].ParameterValue =
                    this.creditors[crIndex].ParameterValue +
                    " (" +
                    this.creditorCodes[coIndex] +
                    ")";
                }
              }
            }

            // this.fcltyTyp = cbsFacilities;
            if (this.fin_id) {
              for (let index of Object.keys(
                this.crdtr_infrmtn_dtls.scrd_loan
              )) {
                this.addCreditorInfoCBS(
                  "Secured",
                  this.crdtr_infrmtn_dtls.scrd_loan[index]
                );
                this.updatePrinAmount(index);
                this.updateIIS(index);
                this.updatePenInt(index);
                this.updateOthCharg(index);
              }
              for (let crIndex of Object.keys(
                this.crdtr_infrmtn_dtls.unscrd_loan
              )) {
                this.addCreditorInfoCBS(
                  "UnSecured",
                  this.crdtr_infrmtn_dtls.unscrd_loan[crIndex]
                );
                this.updateUnPrinAmount(crIndex);
                this.updateUnIIS(crIndex);
                this.updateUnPenInt(crIndex);
                this.updateUnOthCharg(crIndex);
              }
            } else {
              console.log("run 1");
              console.log(this.customer.net_worth_secured_liabilities);
              console.log(this.customer.net_worth_unsecured_liabilities);
              for (let index of Object.keys(
                this.customer.net_worth_secured_liabilities
              )) {
                console.log("run 2");
                this.addCreditorInfoCBS(
                  "Secured",
                  this.customer.net_worth_secured_liabilities[index]
                );
                this.updatePrinAmount(index);
                this.updateIIS(index);
                this.updatePenInt(index);
                this.updateOthCharg(index);
              }
              for (let crIndex of Object.keys(
                this.customer.net_worth_unsecured_liabilities
              )) {
                console.log("run 3");
                this.addCreditorInfoCBS(
                  "UnSecured",
                  this.customer.net_worth_unsecured_liabilities[crIndex]
                );
                this.updateUnPrinAmount(crIndex);
                this.updateUnIIS(crIndex);
                this.updateUnPenInt(crIndex);
                this.updateUnOthCharg(crIndex);
              }
            }
          });
        });

        this.store.dispatch(Actions.GET_STATES).then(() => {
          let states = this.store.getters.getStatesData;

          let stateID;

          for (let index of Object.keys(states)) {
            if (states[index].ParameterValue == this.customer.detail.state) {
              stateID = states[index].id;
            }
          }

          this.store.dispatch(Actions.GET_CITIES, stateID).then(() => {
            this.cities = this.store.getters.getCitiesData;
          });
        });
      }, 1000);

      this.store
        .dispatch(Actions.GET_DOC_PARSER_CCRIS, this.cstmr_id)
        .then(() => {
          const ccris = this.store.getters.getCcrisData;

          if (ccris?.proccessed_data) {
            Object.values(ccris?.proccessed_data).forEach((value, key) => {
              let bank_id,
                fclty = null;
              let banks = this.store.getters.getCreditorsData;

              if (value["bank_no"] && ccris["docparser_type"] == "akpk") {
                let string = value["bank_no"].substring(0, 4);
                const tmp = this.creditorCodes as any;
                let bank = Object.entries(tmp)?.filter(
                  ([key, value2]) => value2 == string
                )[0][0];
                bank_id = Object.entries(banks)?.filter(
                  ([key, value2]) => value2["ParameterValue"] == bank
                );

                bank_id =
                  bank_id.length > 0
                    ? bank_id[0].length > 0
                      ? bank_id[0][1].id
                      : null
                    : null;
              } else if (
                value["bank_no"] &&
                ccris["docparser_type"] == "customer"
              ) {
                bank_id = Object.entries(banks)?.filter(
                  ([key, value2]) =>
                    value2["ParameterValue"] == value["bank_no"]
                );
                bank_id =
                  bank_id.length > 0
                    ? bank_id[0].length > 0
                      ? bank_id[0][0]
                      : null
                    : null;
              }

              if (value["faculty_no"]) {
                const fcltys = this.fcltyTyp as any;
                // fclty = Object.entries(this.fcltyTyp).filter( ([key,value2]) => value2['ParameterValue'].includes(value['faculty_no']))[0][1]?.id;
              }

              let data = {
                loan_type: "",
                nm_of_crdtr: bank_id,
                fclty_typ: fclty,
                fclty_nmbr: value["account_no"] ?? this.customer.nric,
                rstrctrd_in_dmp: "Y",
                lmt: value["limit_amount"]
                  ? parseInt(value["limit_amount"].replace(",", ""))
                  : 0,
                installment_amount: value["installment_amount"]
                  ? parseInt(value["installment_amount"].replace(",", ""))
                  : 0,
                ori_outstndng_blnc: value["outstanding_amount"]
                  ? parseInt(value["outstanding_amount"].replace(",", ""))
                  : 0,
                outstndng_blnc: 0,
                principle_amount: 0,
                ori_principle_amount: value["outstanding_amount"]
                  ? parseInt(value["outstanding_amount"].replace(",", ""))
                  : 0,
                disc_principle_amount: 0,
                ori_iis: 0,
                disc_iis: 80,
                iis: 0,
                ori_penalty_interest: 0,
                disc_penalty_interest: 100,
                penalty_interest: 0,
                ori_other_chargers: 0,
                disc_other_chargers: 0,
                other_chargers: 0,
                tenure: 120,
                interest: this.calculateIntRate(),
                legal_status_date: "",
                installment_method: 1865,
              };

              if (["HSLNFNCE", "PCPASCAR"].includes(value["faculty_no"])) {
                this.crdtr_infrmtn_dtls.scrd_loan.push(data);
              } else {
                this.crdtr_infrmtn_dtls.unscrd_loan.push(data);
              }
            });
            this.showCcrisProcess = false;
          } else {
            this.showCcrisProcess = true;
          }
        });
    },
    handleDocumentRender() {
      this.isLoading = false;
      const pdfPage = this.$refs.pdfRef as any;
      this.pageCount = pdfPage.pageCount;
    },
    setCreditorValue(bank_name) {
      let id = null;
      for (let index of Object.keys(this.oriCreditors)) {
        if (bank_name?.includes("Bank Simpanan Nasional")) {
          id = 3101;
        } else if (
          this.oriCreditors[index].ParameterValue.includes(bank_name)
        ) {
          id = this.oriCreditors[index].id;
        }
      }
      return id;
    },
    setFcltyTypValue(facility_type) {
      let id = "";
      for (let index of Object.keys(this.fcltyTyp)) {
        if (facility_type?.includes("Personal Loan")) {
          id = "120";
        } else if (
          this.fcltyTyp[index].ParameterValue.includes(facility_type)
        ) {
          id = this.fcltyTyp[index].id;
          break;
        }
      }
      return id;
    },
    addCreditorInfoCBS(loan_type, secured) {
      let creditorId = "";
      let fclty_typId = "";

      creditorId = this.setCreditorValue(secured.bank_name);
      fclty_typId = this.setFcltyTypValue(secured.facility_type);

      this.creditor = {
        loan_type: "",
        nm_of_crdtr: creditorId,
        fclty_typ: fclty_typId,
        fclty_nmbr: secured.facility_no,
        rstrctrd_in_dmp: "Y",
        lmt: 0,
        installment_amount: parseFloat(secured.proposed_installment),
        ori_outstndng_blnc: parseFloat(secured.outstanding_balance),
        outstndng_blnc: 0,
        principle_amount: 0,
        ori_principle_amount: parseFloat(secured.outstanding_balance),
        disc_principle_amount: 0,
        ori_iis: 0,
        disc_iis: 80,
        iis: 0,
        ori_penalty_interest: 0,
        disc_penalty_interest: 100,
        penalty_interest: 0,
        ori_other_chargers: 0,
        disc_other_chargers: 0,
        other_chargers: 0,
        tenure: 120,
        interest: this.calculateIntRate(),
        legal_status_date: "",
        installment_method: 1865,
      };

      if (loan_type == "Secured") {
        if (
          secured.facility_type.includes("HP") ||
          secured.facility_type.includes("Car") ||
          secured.facility_type.includes("Vehicle") ||
          secured.facility_type.includes("Auto") ||
          secured.facility_type.includes("Hire Purchase")
        ) {
          this.creditor.tenure = 108;
        } else {
          let icY = this.customer.nric.substring(0, 2);

          if (parseInt(icY) <= 99 && parseInt(icY) > 20) {
            icY = "19" + icY;
          } else {
            icY = "20" + icY;
          }
          let icM = this.customer.nric.substring(2, 4);
          let icD = this.customer.nric.substring(4, 6);
          let birthdate = icD + "/" + icM + "/" + icY;
          let date = icY + "/" + icM + "/" + icD;
          var dateOne = moment([icY, icM, icD]);
          var start = moment(date);

          let today = moment();
          let diff = today.diff(start, "years", true);
          let ageBal = 70 - diff;

          if (ageBal > 0) {
            if (ageBal > 35) ageBal = 35;

            this.creditor.tenure = Math.round(ageBal) * 12;
          } else {
            this.creditor.tenure = 0;
          }
        }

        this.creditor.interest = secured.interest;
        this.crdtr_infrmtn_dtls.scrd_loan.push(this.creditor);
      } else {
        this.crdtr_infrmtn_dtls.unscrd_loan.push(this.creditor);
      }

      console.log(this.crdtr_infrmtn_dtls);
      this.showFacilities = true;
    },
    addCreditorInfo(loan_type) {
      this.creditor = {
        loan_type: "",
        nm_of_crdtr: "",
        fclty_typ: "",
        fclty_nmbr: "",
        rstrctrd_in_dmp: "Y",
        lmt: 0,
        installment_amount: 0,
        ori_outstndng_blnc: 0,
        outstndng_blnc: 0,
        principle_amount: 0,
        ori_principle_amount: 0,
        disc_principle_amount: 0,
        ori_iis: 0,
        disc_iis: 80,
        iis: 0,
        ori_penalty_interest: 0,
        disc_penalty_interest: 100,
        penalty_interest: 0,
        ori_other_chargers: 0,
        disc_other_chargers: 0,
        other_chargers: 0,
        tenure: 120,
        interest: this.calculateIntRate(),
        legal_status_date: "",
        installment_method: 1865,
      };

      if (loan_type == "Secured") {
        this.creditor.interest = 0;
        this.crdtr_infrmtn_dtls.scrd_loan.push(this.creditor);
      } else {
        this.crdtr_infrmtn_dtls.unscrd_loan.push(this.creditor);
      }
    },
    updateCreditorInfoIntRate() {
      // for (let index of Object.keys(this.crdtr_infrmtn_dtls.scrd_loan)) {
      //   this.crdtr_infrmtn_dtls.scrd_loan[
      //     index
      //   ].interest = this.calculateIntRate();
      // }

      for (let index of Object.keys(this.crdtr_infrmtn_dtls.unscrd_loan)) {
        this.crdtr_infrmtn_dtls.unscrd_loan[
          index
        ].interest = this.calculateIntRate();
      }
    },
    calculateIntRate() {
      let intRate = 0;
      if (this.creditorInformation.netIncome < 1500) {
        intRate = 5;
      } else if (
        this.creditorInformation.netIncome >= 1500 &&
        this.creditorInformation.netIncome < 3499
      ) {
        intRate = 6;
      } else if (
        this.creditorInformation.netIncome >= 3500 &&
        this.creditorInformation.netIncome < 5499
      ) {
        intRate = 7;
      } else if (
        this.creditorInformation.netIncome >= 5500 &&
        this.creditorInformation.netIncome < 7499
      ) {
        intRate = 8;
      } else if (this.creditorInformation.netIncome >= 7500) {
        intRate = 9;
      }

      return intRate;
    },
    deleteCreditor(crdt, ind) {
      if (crdt == "scrd_loan") {
        this.crdtr_infrmtn_dtls.scrd_loan.splice(ind, 1);
      } else {
        this.crdtr_infrmtn_dtls.unscrd_loan.splice(ind, 1);
      }
    },
    onUpdateDMPProfiling(dmpProfile) {
      this.$emit("updateDMPProfiling", dmpProfile);
    },
    onGnrtProposal() {
      let maritalStatusId = 0;

      if (this.customer.detail.marital_status == "Married") {
        maritalStatusId = 82;
      } else if (this.customer.detail.marital_status == "Single") {
        maritalStatusId = 83;
      } else if (this.customer.detail.marital_status == "Divorced") {
        maritalStatusId = 155;
      } else if (this.customer.detail.marital_status == "Widowed") {
        maritalStatusId = 156;
      }

      let spouseEmploymentStatus = "false";

      if (this.customer.detail.spouse_employment_status == "NULL") {
        spouseEmploymentStatus = "false";
      } else {
        spouseEmploymentStatus = "true";
      }

      let residentialStatusId = 0;
      if (this.customer.detail.residential_status == "Rented") {
        residentialStatusId = 97;
      } else if (this.customer.detail.residential_status == "Owned") {
        residentialStatusId = 368;
      } else if (this.customer.detail.residential_status == "Parents/Family") {
        residentialStatusId = 369;
      } else if (this.customer.detail.residential_status == "Mortgage/Loan") {
        residentialStatusId = 3215;
      } else if (this.customer.detail.residential_status == "Others") {
        residentialStatusId = 3331;
      }

      let cityId = 0;

      for (let index of Object.keys(this.cities)) {
        if (this.cities[index].ParameterValue == this.customer.detail.city) {
          cityId = this.cities[index].id;
        }
      }

      for (let index of Object.keys(this.cities)) {
        if (this.cities[index].ParameterValue == this.customer.detail.city) {
          cityId = this.cities[index].id;
        }
      }

      let creditorDetailsEntityList = [];

      for (let index of Object.keys(this.crdtr_infrmtn_dtls.scrd_loan)) {
        let rstrctrd = 1;
        if (this.crdtr_infrmtn_dtls.scrd_loan[index].rstrctrd_in_dmp == "N") {
          rstrctrd = 0;
        }
        let cd = {
          CreditorTypeID: 467,
          ProductTypeID: 116,
          NameOfCreditorID: this.crdtr_infrmtn_dtls.scrd_loan[index]
            .nm_of_crdtr,
          FacilityNumber: this.crdtr_infrmtn_dtls.scrd_loan[index].fclty_nmbr,
          FacilityTypeID: this.crdtr_infrmtn_dtls.scrd_loan[index].fclty_typ,
          ModeOfPaymentID: this.crdtr_infrmtn_dtls.scrd_loan[index]
            .installment_method,
          PrincipleAmount: this.crdtr_infrmtn_dtls.scrd_loan[index]
            .principle_amount,
          Interest: this.crdtr_infrmtn_dtls.scrd_loan[index].interest,
          IIS: this.crdtr_infrmtn_dtls.scrd_loan[index].iis,
          PenaltyInterest: this.crdtr_infrmtn_dtls.scrd_loan[index]
            .penalty_interest,
          OtherChargers: this.crdtr_infrmtn_dtls.scrd_loan[index]
            .other_chargers,
          Tenure: this.crdtr_infrmtn_dtls.scrd_loan[index].tenure,
          LegalStatusDate: null,
          RestructureLoan: rstrctrd,
        };

        creditorDetailsEntityList.push(cd);
      }

      for (let index of Object.keys(this.crdtr_infrmtn_dtls.unscrd_loan)) {
        let rstrctrd = 1;
        if (this.crdtr_infrmtn_dtls.unscrd_loan[index].rstrctrd_in_dmp == "N") {
          rstrctrd = 0;
        }
        let cd = {
          CreditorTypeID: 467,
          ProductTypeID: 116,
          NameOfCreditorID: this.crdtr_infrmtn_dtls.unscrd_loan[index]
            .nm_of_crdtr,
          FacilityNumber: this.crdtr_infrmtn_dtls.unscrd_loan[index].fclty_nmbr,
          FacilityTypeID: this.crdtr_infrmtn_dtls.unscrd_loan[index].fclty_typ,
          ModeOfPaymentID: this.crdtr_infrmtn_dtls.unscrd_loan[index]
            .installment_method,
          PrincipleAmount: this.crdtr_infrmtn_dtls.unscrd_loan[index]
            .principle_amount,
          Interest: this.crdtr_infrmtn_dtls.unscrd_loan[index].interest,
          IIS: this.crdtr_infrmtn_dtls.unscrd_loan[index].iis,
          PenaltyInterest: this.crdtr_infrmtn_dtls.unscrd_loan[index]
            .penalty_interest,
          OtherChargers: this.crdtr_infrmtn_dtls.unscrd_loan[index]
            .other_chargers,
          Tenure: this.crdtr_infrmtn_dtls.unscrd_loan[index].tenure,
          LegalStatusDate: null,
          RestructureLoan: rstrctrd,
        };

        creditorDetailsEntityList.push(cd);
      }

      const details = {
        _customerDetailsEntity: {
          CustomerID: this.customer.nric,
          CustomerName: this.customer.name,
          DateofBirth: this.customer.birthdate,
          ContactNumber: this.customer.phone,
          Email: this.customer.email,
          NetIncome: this.creditorInformation.netIncome,
          MaritalStatusID: maritalStatusId,
          NumberOfDependent: this.customer.detail.no_of_dependant,
          SpouseEmploymentStatus: spouseEmploymentStatus,
          ResidentialStatusID: residentialStatusId,
          CityID: cityId,
        },
        _creditorDetailsEntityList: creditorDetailsEntityList,
        _customerMatrixAnswerEntity: this.customerMatrix,
        _customerProfilingAnswerEntity: this.customerProfiling,
      };

      const data = {
        user_id: 0,
        created_by: 0,
        details: details,
      };

      Swal.fire({
        title: "Loading!",
        text: "Please wait!",
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
      });

      this.store.dispatch(Actions.GET_CREDITOR_PROFILING, data).then(() => {
        let dmpDstrbtnsFromCBS = this.store.getters.getCreditorProfilingData
          .dmpDstrbtns;

        let hasRemarkFromCBS = false;
        let hasReturnMessageFromCBS = false;
        let returnMessage = [];

        returnMessage = this.store.getters.getCreditorProfilingData
          .returnMessage;

        if (returnMessage.length > 0) {
          hasReturnMessageFromCBS = true;
        }

        if (!hasReturnMessageFromCBS) {
          for (let index of Object.keys(dmpDstrbtnsFromCBS)) {
            if (dmpDstrbtnsFromCBS[index].remarks.length > 0) {
              hasRemarkFromCBS = true;
            }
          }

          if (!hasRemarkFromCBS) {
            this.showSaveBttn = true;
          }

          this.dmpDstrbtns = this.store.getters.getCreditorProfilingData.dmpDstrbtns;

          const customerData = {
            id: this.cstmr_id,
            detail: {
              dmp_profiling: this.store.getters.getCreditorProfilingData
                .customerProfiling,
            },
          };

          this.store
            .dispatch(Actions.UPDATE_CUSTOMER, customerData)
            .then(() => {
              this.onUpdateDMPProfiling(
                this.store.getters.getCreditorProfilingData.customerProfiling
              );
            });

          Swal.fire({
            title: "Congratulation!",
            text: "Proposal Distribution has been generated!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            this.dmpProposalReady = true;
          });
        } else {
          Swal.fire({
            title: "Sorry!",
            html:
              "Proposal Distribution Canno be generated!<br/>" +
              returnMessage[0],
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
    },
    onSave() {
      const data = {
        user_id: this.cstmr_id,
        created_by: this.store.getters.currentUser.id,
        type: "Financial Resolution (DMP)",
        raw: {
          isStepup:
            this.customerMatrix.CustomerMatrixAnswer1 == "1911" ? false : true,
          customer: this.customer,
          creditorInformation: this.creditorInformation,
          customerMatrix: this.customerMatrix,
          customerProfiling: this.customerProfiling,
          crdtr_infrmtn_dtls: this.crdtr_infrmtn_dtls,
          dmpDstrbtns: this.dmpDstrbtns,
        },
      };

      this.store.dispatch(Actions.STORE_FINANCIAL_RESOLUTION, data).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Proposal Distribution has been generated!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          this.router.push({
            path: `/customers/details/${this.cstmr_id}/dmp-plan/list`,
          });
        });
      });
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
    beforeRemove(uploadFile, uploadFiles) {
      console.log(uploadFile, uploadFiles);
      Swal.fire({
        title: "Congratulation!",
        text: "Are you sure to delete this item?.",
        icon: "info",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        //cancelButtonText: 'No, I am not!',
        confirmButtonText: "OK",
      }).then(
        () => true,
        () => false
      );
    },
    handleChange(uploadFile, uploadFiles) {
      const formData = new FormData();

      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      formData.append("file", uploadFile.raw);
      formData.append("customer_id", this.cstmr_id as any);
      formData.append("docparser_type", "akpk");
      formData.append("name", uploadFile?.raw?.name);

      this.store.dispatch(Actions.UPLOAD_CCRIS, formData).then(() => {
        loading.close();
      });
    },
    handleChange2(uploadFile, uploadFiles) {
      const formData = new FormData();

      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      formData.append("file", uploadFile.raw);
      formData.append("customer_id", this.cstmr_id as any);
      formData.append("docparser_type", "customer");
      formData.append("name", uploadFile?.raw?.name);

      this.store.dispatch(Actions.UPLOAD_CCRIS, formData).then(() => {
        loading.close();
      });
    },
  },
});
