
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Information1 from "@/components/dropdown/Information1.vue";
import ElCurrencyInput from "@/components/CurrencyInput.vue";
import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";
import ElInfo from "@/components/ElInfo.vue";

export default defineComponent({
  name: "ProfilingQuestionnaire",
  props: ["customerMatrix", "customerProfiling"],
  components: {
    Field,
    // Form,
  },
  async mounted() {
    this.init();
  },
  data() {
    const schema = Yup.object({});

    const customerMatrixAnswerEntity = {
      CustomerMatrixAnswer1: "",
      CustomerMatrixAnswer2: "",
      CustomerMatrixAnswer3: "",
      CustomerMatrixAnswer4: "",
      CustomerMatrixAnswer5: "",
      CustomerMatrixAnswer6: "",
      CustomerMatrixAnswer7: "",
      CustomerMatrixAnswer8: "",
      CustomerMatrixAnswer9: "",
      CustomerMatrixAnswer10: "",
      CustomerMatrixAnswer11: "",
      CustomerMatrixAnswer12: "",
    };

    const customerProfilingAnswerEntity = {
      CustomerProfilingAnswer1: "",
      CustomerProfilingAnswer2: "",
      CustomerProfilingAnswer3: "",
      CustomerProfilingAnswer4: "",
      CustomerProfilingAnswer5: "",
      CustomerProfilingAnswer6: "",
      CustomerProfilingAnswer7: "",
      CustomerProfilingAnswer8: "",
      CustomerProfilingAnswer9: "",
      CustomerProfilingAnswer10: "",
      CustomerProfilingAnswer11: "",
      CustomerProfilingAnswer12: "",
      CustomerProfilingAnswer13: "",
      CustomerProfilingAnswer14: "",
      CustomerProfilingAnswer15: "",
      CustomerProfilingAnswer16: "",
      CustomerProfilingAnswer17: "",
      CustomerProfilingAnswer18: "",
      RecurringNonRecurringReasonID: "",
    };

    const profQuestionAns = [
      { value: "1910", label: "Yes" },
      { value: "1911", label: "No" },
    ];

    const SeriousMedicalCondition = [
      { value: "854", label: "Yes" },
      { value: "855", label: "No" },
    ];

    const OwnAnyAssets = [
      { value: "856", label: "Yes" },
      { value: "857", label: "No" },
    ];

    const RequiringSpecialCare = [
      { value: "858", label: "Yes" },
      { value: "859", label: "No" },
    ];

    const FinancialPredicamentReason1 = [
      { value: "682", label: "Recurring" },
      { value: "681", label: "Non Recurring" },
    ];

    const FinancialPredicamentReasonForRecurring = [
      {
        value: "4476",
        label: "Become disabled from accident/sickness (salary earner)",
      },
      { value: "4475", label: "Borrower absconded-guarantor" },
      {
        value: "4479",
        label:
          "Borrowing from licensed money lender (non-bank/non-cooperatives)",
      },
      { value: "4480", label: "Borrowing from unlicensed money lender" },
      { value: "4477", label: "Calamity (floods, fire, accident etc)" },
      {
        value: "4469",
        label: "Death/disable/sickness/divorced/loss of breadwinner",
      },
      { value: "4474", label: "Failed investment" },
      { value: "4466", label: "Failure/slowdown in Business" },
      { value: "4470", label: "Financing lavish life styles" },
      { value: "4471", label: "Gambling" },
      { value: "4472", label: "High cost of living" },
      {
        value: "4463",
        label: "High medical expenses incurred for handicapped children",
      },
      {
        value: "4464",
        label: "High medical expenses incurred for parents/in laws",
      },
      {
        value: "4465",
        label: "High medical expenses incurred for self/children/dependents",
      },
      {
        value: "4461",
        label: "Kiting (use credit cards/OD to pay others credit cards/loans)",
      },
      { value: "4460", label: "Lost control on usage of credit cards" },
      { value: "4468", label: "Lost job/retrenched (currently unemployed)" },
      { value: "4467", label: "Reduction in income (salary earner only)" },
      { value: "4478", label: "Special cases moratorium" },
      {
        value: "4462",
        label:
          "Took loan / cash advance to pay off money lenders (fully settled)",
      },
      { value: "4473", label: "Unexpected financial commitment" },
    ];

    const FinancialPredicamentReasonForNonRecurring = [
      {
        value: "4497",
        label: "Become disabled from accident/sickness (salary earner)",
      },
      { value: "4496", label: "Borrower absconded-guarantor" },
      {
        value: "4500",
        label:
          "Borrowing from licensed money lender (non-bank/non-cooperatives)",
      },
      { value: "4501", label: "Borrowing from unlicensed money lender" },
      { value: "4498", label: "Calamity (floods, fire, accident etc)" },
      {
        value: "4490",
        label: "Death/disable/sickness/divorced/loss of breadwinner",
      },
      { value: "4495", label: "Failed investment" },
      { value: "4487", label: "Failure/slowdown in Business" },
      { value: "4491", label: "Financing lavish life styles" },
      { value: "4492", label: "Gambling" },
      { value: "4493", label: "High cost of living" },
      {
        value: "4484",
        label: "High medical expenses incurred for handicapped children",
      },
      {
        value: "4485",
        label: "High medical expenses incurred for parents/in laws",
      },
      {
        value: "4486",
        label: "High medical expenses incurred for self/children/dependents",
      },
      {
        value: "4482",
        label: "Kiting (use credit cards/OD to pay others credit cards/loans)",
      },
      { value: "4481", label: "Lost control on usage of credit cards" },
      { value: "4489", label: "Lost job/retrenched (currently unemployed)" },
      { value: "4488", label: "Reduction in income (salary earner only)" },
      { value: "4499", label: "Special cases moratorium" },
      {
        value: "4483",
        label:
          "Took loan / cash advance to pay off money lenders (fully settled)",
      },
      { value: "4494", label: "Unexpected financial commitment" },
    ];

    const showFinancialPredicamentReasonForRecurring = true;
    const showFinancialPredicamentReasonForNonRecurring = false;

    const YearsToRetirement = [
      { value: "697", label: "3-5 YEARS" },
      { value: "696", label: "6-10 YEARS" },
      { value: "698", label: "LESS THAN 3 YEARS" },
      { value: "695", label: "MORE THAN 11 YEARS" },
      { value: "699", label: "Unemployed" },
    ];

    const ExpectationVsDMP = [
      { value: "702", label: "Gap above 30%" },
      { value: "700", label: "Meet my expectation" },
    ];

    const AgeOfCustomer = [
      { value: "914", label: ">=60 Years" },
      { value: "911", label: "30-39 Years" },
      { value: "912", label: "40-49 Years" },
      { value: "913", label: "50-59 Years" },
      { value: "910", label: "Below 30 Years" },
    ];

    const SourceOfIncome = [
      {
        value: "946",
        label: "3rd Party Income (depend on relative/spouse/children/etc)",
      },
      { value: "942", label: "Own Income (fixed - salary earner)" },
      {
        value: "945",
        label:
          "Own Income (fixed - Self employed/additional/part time/commission)",
      },
      { value: "944", label: "Own Income (fixed < variable)" },
      { value: "943", label: "Own Income (fixed > variable)" },
      {
        value: "947",
        label: "Received Monetary Assistance (Socso/Baitulmal/Wellfare Dept)",
      },
    ];

    const DSR = [
      { value: "964", label: "<40%" },
      { value: "962", label: ">60%" },
      { value: "963", label: "40%-60%" },
      { value: "932", label: "41%-50%" },
      { value: "933", label: "51%-60%" },
      { value: "934", label: "61%-70%" },
      { value: "935", label: "71% and above" },
      { value: "931", label: "Less than 40%" },
    ];

    const NetWorth = [
      { value: "5533", label: "Break-even" },
      { value: "949", label: "Deficit" },
      { value: "948", label: "Surplus" },
    ];

    const YearsWithCurrentEmployee = [
      { value: "923", label: "1-3 Years" },
      { value: "922", label: "3-5 Years" },
      { value: "921", label: "5-10 Years" },
      { value: "924", label: "Less Than 1 Year" },
      { value: "920", label: "More Than 10 Years" },
      { value: "925", label: "Retiree / Unemployed" },
    ];

    const RepaymentCommitment = [
      { value: "928", label: "MIA Less Than 3 Months" },
      { value: "929", label: "MIA More Than 3 Months" },
      { value: "930", label: "More Than 6 Months" },
      { value: "926", label: "No Fixed Repayment Commitment" },
      { value: "927", label: "Prompt" },
    ];

    const DebtWithOther = [
      {
        value: "956",
        label: "Licensed Credit Provider (Co-op, Court Mammoth, etc)",
      },
      { value: "955", label: "No" },
      { value: "957", label: "Unlicensed Moneylender" },
    ];

    const OveralDSR = [
      { value: "917", label: "< 40%" },
      { value: "915", label: "> 60%" },
      { value: "916", label: "40%-60%" },
    ];

    const M2 = [
      { value: "960", label: "No" },
      { value: "959", label: "Yes" },
    ];

    const NatureOfJob = [
      { value: "937", label: "Government" },
      { value: "939", label: "Own Business" },
      { value: "938", label: "Private" },
      { value: "936", label: "Professional" },
      { value: "940", label: "Retiree With Pension" },
      { value: "941", label: "Unemployed/Retiree With No Pension" },
    ];

    const SecondApplication = [
      { value: "968", label: "No" },
      { value: "967", label: "Yes" },
    ];

    return {
      customerMatrixAnswerEntity,
      customerProfilingAnswerEntity,
      profQuestionAns,
      SeriousMedicalCondition,
      RequiringSpecialCare,
      OwnAnyAssets,
      FinancialPredicamentReason1,
      FinancialPredicamentReasonForRecurring,
      FinancialPredicamentReasonForNonRecurring,
      showFinancialPredicamentReasonForRecurring,
      showFinancialPredicamentReasonForNonRecurring,
      YearsToRetirement,
      ExpectationVsDMP,
      AgeOfCustomer,
      SourceOfIncome,
      DSR,
      NetWorth,
      YearsWithCurrentEmployee,
      RepaymentCommitment,
      DebtWithOther,
      OveralDSR,
      M2,
      NatureOfJob,
      SecondApplication,
      schema: schema,
    };
  },
  setup() {
    return {};
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("Profiling Questionnaire", []);
      this.customerMatrixAnswerEntity = this.customerMatrix;
      this.customerProfilingAnswerEntity = this.customerProfiling;
    },
    onChangeCustomerProfilingAnswer4() {
      if (this.customerProfiling.CustomerProfilingAnswer4 == "682") {
        this.showFinancialPredicamentReasonForRecurring = true;
        this.showFinancialPredicamentReasonForNonRecurring = false;
        this.customerProfilingAnswerEntity.RecurringNonRecurringReasonID = "";
      } else if (this.customerProfiling.CustomerProfilingAnswer4 == "681") {
        this.showFinancialPredicamentReasonForRecurring = false;
        this.showFinancialPredicamentReasonForNonRecurring = true;
        this.customerProfilingAnswerEntity.RecurringNonRecurringReasonID = "";
      }
    },
  },
});
